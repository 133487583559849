




import { Component, Mixins } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component
export default class Control extends Mixins(PermissionsMixin) {
  private mounted () {
    if (this.$route.name === 'manager.control') {
      if (this.hasPermission(this.Permission.COURSE_VIEW)) {
        this.$router.replace({ name: 'manager.control.courses' })
      } else if (this.hasPermission(this.Permission.MG_VIEW_ALL)) {
        this.$router.replace({ name: 'manager.control.groups' })
      } else {
        this.$router.replace({ name: 'manager' })
      }
    }
  }
}
